.linkBoleto {
  text-decoration: none;
  font-weight: bold;
  color: #f75a48;
}

.disabledLinkBoleto {
  color: inherit;
  font-weight: normal;
}

.cardLinkBoletos {
  border-radius: 7px;
  text-align: center;
  font-weight: bold !important;
  margin: 7px 10px -7px auto !important;
  color: white;
  background: rgb(224, 224, 224);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.cardLinkBoletoColorAVencer {
  background: #FBBC05;
}

.cardLinkBoletoColorAtrasado {
  background: #EA4335;
}

.cardLinkBoletoColorPago {
  background: #34A853;
}