header#main-header {
    width: 100%;
    height: 60px;
    background: #f75a48;
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

header#main-header #title-header {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}