.grow-font-size {
  font-size: 15px;
  font-weight: bold;
}

.card-status {
  margin: -16px -6px 16px -16px;
  height: 6px;
  border-radius: 3px 3px 0 0;
}

.topBody {
  padding: 5px;
  margin: 5 auto;
}
.topBody > h2 {
  padding: 10px 0px;
  font-weight: 400;
}
