* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
body {
  background-color: #f3f3f3;
  color: #333;
}
body header h2 {
  text-align: center;
}
