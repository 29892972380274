.corporate-name {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  color: #fff;
  background: #f75a48;
}
.corporate-name h2 {
  font-size: 18px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  text-align: center;
}

.corporate-name-container {
  margin-top: 8px;
  margin-bottom: 8px;
}
